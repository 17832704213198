import React, { Fragment } from "react"
import { useOutlet } from "./outletFunction";
import CardComp from "../../../component/globalComponent/CardComp";
import AlertComp from "../../../component/alert/AlertComp";
import ButtonGroup from "../../../component/globalComponent/ButtonGroup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons"
import { faCircle, faPlus } from "@fortawesome/free-solid-svg-icons"
import ReactLoading from 'react-loading';
import FormTextSingleComp from "../../../component/globalComponent/FormTextSingleComp";
import Modal from "../../../component/Modal";
import PagePagination from "../../../component/globalComponent/PagePagination";
import SearchComp from "../../../component/globalComponent/SearchComp";
import Button from "../../../component/globalComponent/Button";

const OutletPage = (props) => {
    const { isLoading, listData, view, handleAdd, handleCancel, alertValue, handleCloseAlert,
        handleSubmit, pageValue, handleEdit, handleDelete, handleChange, handleBlur, formData,
        modalValue, handleCloseModal, handleSubmitDelete, handlePaginate, 
        handleChangeSearch, handleSearch, handleKeyDownSearch, handleShowLogo,
        closeModalHandler, modalState, ictIsConnect, handleCheckConnection, ictStateText
    } = useOutlet();

    const buttonNode = () => {
        return(
            <ButtonGroup>
                <Button onClick={handleSubmit} className='button bg-red-500  h-full text-white' color="primary-light">Save</Button>
                <Button onClick={handleCheckConnection} className='button bg-green-500  h-full text-white' color="success-light">Check Connection</Button>
                <Button onClick={handleCancel} className='button bg-blue-500  h-full text-white' color="light">Cancel</Button>
            </ButtonGroup>
        )
    }
    
    return(
        <Fragment>
            <Modal
                isOpen={modalState.isOpen}
                onClose={closeModalHandler}
                title={modalState.title}
                // onSubmit={handleSubmitDelete}
                // onSubmitText={"Delete"}
                // submitColor={"danger"}
            >
                {modalState.content}
            </Modal>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                />
            <CardComp title="Outlet" ButtonNode={view === 'create' ? buttonNode : null}>
                { view === 'list' &&    
                    <div className='px-5'>
                         <div className="grid grid-cols-4 gap-4 mb-4">
                            <div className='col-span-3'>
                                <ButtonGroup>
                                    <Button
                                        color="purple-light"
                                        onClick={handleAdd}
                                    >
                                        <FontAwesomeIcon icon={faPlus} /> Add Logo
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <SearchComp
                                pageValue={pageValue}
                                handleChangeSearch={handleChangeSearch}
                                handleSearch={handleSearch}
                                handleKeyDownSearch={handleKeyDownSearch}
                            />
                        </div>                        
                        <div className="relative">
                            <table className='table-auto w-full table'>
                                <thead>
                                    <tr>
                                        <th className='text-right'>#</th>
                                        <th>Name</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { listData.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className='text-right'>{pageValue.offset+1+index}</td>
                                                <td>{post.nama}</td>
                                                <td className='text-center'>
                                                    <ButtonGroup>
                                                        <Button onClick={() => handleEdit(post)} className='button btn-icon bg-blue-400 px-5 py-2 btn-danger text-white' color="primary-outline">
                                                            <FontAwesomeIcon icon={faEdit}/>
                                                        </Button>
                                                        <Button  onClick={() => handleDelete(post)} className='button btn-icon bg-red-400 px-5 py-2 btn-danger text-white' color="danger-outline">
                                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </div>                
                }
                { view === 'create' &&
                    <div className='mt-5 px-5'>
                        <FormTextSingleComp
                            {...formData.nama}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.invoice_display}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.logo_display}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // readOnly={true}
                            handleClick={handleShowLogo}
                        />
                        { formData.logo_img.value &&
                            <img src={formData.logo_img.value} className={`w-[200px] ml-5`} alt="logo"/>
                        }
                        <FormTextSingleComp
                            {...formData.base_endpoint}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.display_mode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.host}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.serial_port}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.server_key}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.midtrans_server_key}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.midtrans_client_key}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.xendit_api_key}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.xendit_public_key}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.xendit_callback_verify_token}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <FormTextSingleComp
                            {...formData.xendit_account_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <div className="flex flex-row">
                            <img src={require('../../../assets/bill-acceptor.png')} alt='bill_acceptor' className='w-[60px]'/>
                            <div className={`px-5 pt-5 ${ictIsConnect ? 'text-green-500' : 'text-gray-400'}`}>
                                <FontAwesomeIcon icon={faCircle}/> { ictStateText }
                            </div>
                        </div>
                    </div>
                }

                { isLoading && 
                    <div className='w-full flex flex-row justify-center'>
                        <ReactLoading type={'cylon'} color="#0366fc" className="text-center"/>
                    </div>                                                 
                }
                
            </CardComp>
            <Modal
                isOpen={modalValue.show}
                onClose={handleCloseModal}
                title={modalValue.title}
                onSubmit={handleSubmitDelete}
                onSubmitText={"Delete"}
                submitColor={"danger"}
            >
                {modalValue.text}
            </Modal>
        </Fragment>
    )
}
export default OutletPage;