// eslint-disable-next-line 
export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        // development: "https://vendingmachine-be.myappscenter.com",
        // production: "https://vendingmachine-be.myappscenter.com", 
        // video: "https://vendingmachinenode.myappscenter.com", 
        development: "https://be-vending-v2.akinons.id",
        production: "https://be-vending-v2.akinons.id", 
        video: "http://127.0.0.1:4000", 
        // development: "https://4324-2404-8000-1000-e42-446f-934b-e2ff-a808.ngrok-free.app",
        // production: "https://4324-2404-8000-1000-e42-446f-934b-e2ff-a808.ngrok-free.app", 
        // video: "https://4324-2404-8000-1000-e42-446f-934b-e2ff-a808.ngrok-free.app", 
        // video: "http://127.0.0.1:4000", 
        // development: "https://dataaaf.myappscenter.com",
        // production: "https://dataaaf.myappscenter.com", 
    },
    localStorageKey : {      
      USER: "__vendMachineClientUser",
      ACCESS_TOKEN: "__vendMachineClientToken",      
      PERUSAHAAN : '_vendMachineClientperusahaanInfo',
    },
    cookiesName : 'vendMachine_client',    
    expiresSession : 86400,
    itemPerPage : 15,
    timeOutValue : 10000,
    endPoint : {
      // auth
      permissionGroup : '/auth/api/v1/permission-group/',
      login : `/auth/login/`,
      outlet : `/payment/api/v1/outlet/`,
      user : `/auth/api/v1/user/`,
      video : `/payment/api/v1/video/`,
      postVideo : `/payment/api/v1/post-video/`,
      changePassword : `/auth/change-password/`,
      transaksi : `/payment/api/v1/payment/`,
      dashboard : `/payment/api/v1/dashboard/`,
      checkLogin : `/auth/check-login/`,
      masterLogo : `/payment/api/v1/logo/`,
      masterBaseEndpoint : `/payment/api/v1/base-endpoint/`,
      checkConnectionIct : `/payment/api/v1/machine-state/`,
      paymentConfirmation : `/payment/api/v1/payment-confirmation/`,
      invoiceInfo : `/payment/api/v1/get-invoice-info/`,

      masterVa : `/payment/api/v1/master-va/`,
      maxVa : `/payment/api/v1/max-va/`,
      bulkUpdateMasterVa : `/payment/api/v1/bulk-update-va/`,

      createPaymentUrl : `/accounting/api/v1/create-payment-url/`,
      saldoDeposite : `/accounting/api/v1/saldo-deposite/`,
      postCampaign : `/ads/api/v1/post-campaign/`
    }
}